<template>
  <div>
    <div class="vertical_title flex">修改密码</div>
    <PasswordForm></PasswordForm>
  </div>
</template>

<script>
import PasswordForm from '../../../components/form/password.vue'
export default {
  components: { PasswordForm },
  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="less" scoped></style>
