<template>
  <div class="form">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="135px">
      <!-- <el-form-item label="旧密码" prop="old_password">
        <el-input placeholder="请输入旧密码" show-password v-model="ruleForm.old_password"></el-input>
      </el-form-item> -->
      <el-form-item label="新密码" prop="password">
        <el-input placeholder="请输入新密码" show-password v-model="ruleForm.password"></el-input>
      </el-form-item>
      <el-form-item label="确认新密码" prop="right_password">
        <el-input placeholder="请再次输入新密码" show-password v-model="ruleForm.right_password"></el-input>
      </el-form-item>
      <el-form-item>
        <div class="btn" @click="saveClick">保存</div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { updatePwd } from '@/service/api/user'

export default {
  data() {
    const validatePassword = (rule, value, callback) => {
      if (value == this.ruleForm.password) {
        callback()
      } else {
        callback(new Error('两次密码不相同'))
      }
    }
    return {
      ruleForm: {
        password: '',
        // old_password: '',
        right_password: ''
      },
      rules: {
        password: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
        // old_password: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
        right_password: [{ required: true, validator: validatePassword, trigger: 'blur' }]
      }
    }
  },

  mounted() {},

  methods: {
    // 修改密码
    async updatePwd() {
      const res = await updatePwd(this.ruleForm)
      if (res.code == 1) {
        this.$message.success('修改成功，请重新登录')
        localStorage.clear()
        this.$store.state.userInfo = ''
        this.$router.push('/account-login')
      } else {
        this.$message.error(res.msg)
      }
    },
    saveClick() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // 修改密码
          this.updatePwd()
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-input {
  width: 425px;
}
/deep/ .el-form-item__label {
  text-align: left;
  font-size: 16px;
}
/deep/ .el-form-item {
  margin-bottom: 30px;
}
/deep/ .el-input .el-input__inner:focus {
  border-color: #dddddd !important;
}

/deep/ .el-input__inner {
  width: 433px;
}
.form {
  padding: 20px 16px 0 16px;
}
.btn {
  width: 114px;
  height: 36px;
  color: #fff;
  cursor: pointer;
  border-radius: 3px;
  line-height: 36px;
  text-align: center;
  font-size: @font_size_16;
  background-color: @color_one;
}
</style>
